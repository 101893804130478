@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Roboto-Regular;
  src: url(../src//assets//css/fonts/Roboto-Regular-14.ttf);
}

@font-face {
  font-family: Roboto-Regular;
  font-weight: 500;
  src: url(../src//assets//css/fonts/Roboto-Medium-12.ttf);
}

@font-face {
  font-family: Roboto-Regular;
  font-weight: bold;
  src: url(../src//assets//css/fonts/Roboto-Bold-3.ttf);
}

body * {
  margin: 0;
  font-family: Roboto-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body * {
  margin: 0;
  font-family: Roboto-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* 
body * {
  font-family: Roboto-Regular, Roboto-Bold;
} */